<template>
  <a-header dotted class="min-h-48"></a-header>
  <main>
    <div class="w-11/12 md:w-4/5 xl:w-4/6 mx-auto">
      <div class="-mt-44 mb-10">
        <h1 class="pt-8 mb-6 text-3xl text-gray-lightest">
          {{ t('welcomeUser') }} {{ userName }}!
        </h1>

        <WelcomeCard>
          <div class="text-3xl mb-3">
            <h2>{{ t('welcomeSnapshot') }}</h2>
          </div>
          <div class="flex text-sm mb-1">
            <div class="pr-3">
              <p>
                <span v-if="test?.cycle">{{ test.cycle.name }} &mdash; </span>
                <span class="font-bold">{{ test?.name }}</span>
                {{ test && getLangName(test.lang) }}
              </p>
            </div>
            <router-link :to="{ name: 'applicant.selectTest' }" class="select-test-link">
              {{ t('change') }}
              <span class="sr-only">{{ t('srChangeTest') }}</span>
            </router-link>
          </div>
          <p>
            {{ t('welcomeDescription') }}
          </p>
        </WelcomeCard>
      </div>

      <a-collapsible-card expanded data-cy="card-intro">
        <div class="flex items-center">
          <div class="flex-none">
            <img src="@/assets/icons/bookmark.svg" alt="" class="w-12" />
          </div>
          <h3 class="text-blue-darkest flex-grow ml-6">{{ t('step1Title') }}</h3>
        </div>
        <template #content>
          <div
            class="prose card-content"
            v-html="t('step1Description')"
            data-cy="txt-intro"
          ></div>
        </template>
      </a-collapsible-card>

      <a-fixed-card class="mt-10" data-cy="card-system-check">
        <template #icon>
          <img src="@/assets/icons/reloading-screen.svg" alt="" class="w-12" />
        </template>

        <template #title>
          <h3 class="text-blue-darkest ml-6">{{ t('step2Title') }}</h3>
        </template>

        <template #content>
          <div class="card-content">
            <div class="prose" data-cy="txt-system-check">
              <i18n-t keypath="step2Description" tag="p" :i18n="i18n">
                <template #mandatoryCompletion>
                  <strong>{{ t('mandatoryCompletion') }}</strong>
                </template>
                <template #troubleshootingLink>
                  <router-link
                    :to="{ name: 'applicant.troubleshooting' }"
                    target="_blank"
                  >
                    {{ t('troubleshootingTips') }}
                    <span class="sr-only">{{ t('srOpenNewTab') }}</span>
                  </router-link>
                </template>
              </i18n-t>
            </div>

            <SuccessfullyCompletedNotice
              v-if="completedStep2"
              data-cy="txt-system-check-completed"
            />

            <div class="flex justify-end pt-5">
              <a-card-link
                :to="{ name: 'applicant.systemCheck' }"
                data-cy="link-system-check"
              >
                {{ t('systemCheck') }}
              </a-card-link>
            </div>
          </div>
        </template>
      </a-fixed-card>

      <a-fixed-card class="mt-10" data-cy="card-practice">
        <template #icon>
          <img src="@/assets/icons/wrench.svg" alt="" class="w-12" />
        </template>

        <template #title>
          <h3 class="text-blue-darkest ml-6">{{ t('step3Title') }}</h3>
        </template>

        <template #content>
          <div class="card-content">
            <div
              class="prose"
              v-html="t('step3Description')"
              data-cy="txt-practice"
            ></div>

            <SuccessfullyCompletedNotice
              v-if="completedStep3"
              data-cy="txt-practice-completed"
            />

            <div v-if="!completedStep2" class="flex my-3">
              <img
                src="@/assets/icons/exclamation-triangle.svg"
                alt=""
                class="w-5 mr-2"
              />
              <strong>{{ t('completeStep2First') }}</strong>
            </div>

            <div class="flex justify-end pt-5">
              <a-card-link
                :disabled="!completedStep2"
                :to="{ name: 'applicant.practiceTest' }"
                data-cy="link-practice"
              >
                {{ t('practiceSnapshot') }}
              </a-card-link>
            </div>
          </div>
        </template>
      </a-fixed-card>

      <a-fixed-card class="mt-10" data-cy="card-start-test">
        <template #icon>
          <img src="@/assets/icons/video.svg" alt="" class="w-12" />
        </template>

        <template #title>
          <h3 class="text-blue-darkest ml-6">{{ t('step4Title') }}</h3>
        </template>

        <template #content>
          <div class="card-content">
            <div
              class="prose"
              v-html="t('step4Description', { numQuestions })"
              data-cy="txt-start-test"
            ></div>

            <SuccessfullyCompletedNotice v-if="completedStep4" />

            <div v-if="!completedStep3" class="flex my-3">
              <img
                src="@/assets/icons/exclamation-triangle.svg"
                alt=""
                class="w-5 mr-2"
              />
              <strong>{{ t('completeStep3First') }}</strong>
            </div>

            <div class="flex justify-end pt-5">
              <a-card-link
                :disabled="!completedStep3"
                :to="{ name: 'applicant.test' }"
                data-cy="link-start-test"
              >
                {{ t('startSnapshot') }}
              </a-card-link>
            </div>
          </div>
        </template>
      </a-fixed-card>
    </div>
  </main>
</template>

<script lang="ts">
import { computed, defineComponent, ref, toRef } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { authStore } from '@/store/auth';

import { getLangName } from '@/services/langNames';
import SuccessfullyCompletedNotice from '@/components/common/SuccessfullyCompletedNotice.vue';
import WelcomeCard from '@/components/common/WelcomeCard.vue';

export default defineComponent({
  name: 'ApplicantHome',
  components: { SuccessfullyCompletedNotice, WelcomeCard },
  setup() {
    const applicant = useStore(applicantStore);
    const auth = useStore(authStore);
    const i18n = useI18n();

    const userName = computed(() => auth.user?.profile?.givenname);

    const test = toRef(applicant, 'currentTest');

    const completedStep2 = computed(() => applicant.isSystemCheckDone);
    const completedStep3 = computed(() => applicant.isPracticeDone);
    const completedStep4 = ref(false);

    // TODO: This number should be coming from the api (maybe we could save it in the store?)
    const numQuestions = ref(3);

    return {
      userName,
      completedStep2,
      completedStep3,
      completedStep4,
      numQuestions,
      test,
      getLangName,
      t: i18n.t,
      i18n
    };
  }
});
</script>

<style lang="postcss" scoped>
.select-test-link {
  @apply text-blue transition-colors;

  &:hover {
    @apply text-blue-darkest underline;
  }

  &:focus-visible {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }

  /* Styling does not apply unless :moz-focusring is separated from :focus-visible, which is why it is repeated below */
  &:-moz-focusring {
    @apply outline-none ring-2 ring-blue ring-offset-2;
  }
}

.card-content {
  margin-left: 4.5rem;
}
</style>

<i18n>
{
  "en": {
    "change": "Change",
    "completeStep2First": "You must first complete Step 2: System Requirements Check",
    "completeStep3First": "You must first complete Step 3: Practice Snapshot",
    "mandatoryCompletion": "System Requirements Check is required",
    "practiceSnapshot": "Practice Snapshot",
    "srChangeTest": "Change selected test",
    "srOpenNewTab": "(opens in a new tab)",
    "startSnapshot": "Start Snapshot Interview",
    "step1Description": "<p>The aim of Snapshot is to mimic the setting of an asynchronous virtual interview to give programs a glimpse into your communication and interpersonal skills via video response. This is also an opportunity for you to demonstrate your personal attributes to the programs you are applying to.</p><p>Review the Altus Suite Snapshot Applicant Information page on <a href='https://www.takealtus.com'>TakeAltus.com</a> before you begin. It's obligatory that you complete Step 2: System Requirements Check to ensure your computer will be adequate come the day you take Snapshot. Step 3: Practice Snapshot, is a mandatory requirement before moving on to Step 4: Start Snapshot Interview. It will help you feel comfortable before completing Step 4, when your video responses will be submitted to your selected programs.</p><p>Before starting Step 4: Start Snapshot Interview, we recommend setting aside <strong>10 minutes in a quiet location</strong> to complete it.</p>",
    "step1Title": "Step 1: Learn About Snapshot",
    "step2Description": "Completing the {mandatoryCompletion} to ensure you have a smooth technical experience. Visit our {troubleshootingLink} if you encounter any technical issues.",
    "step2Title": "Step 2: System Requirements Check",
    "step3Description": "<p>Familiarize yourself with the format of Snapshot by completing 2 practice questions. You will be able to re-record and re-watch your videos as much as you like.</p>",
    "step3Title": "Step 3: Practice Snapshot",
    "step4Description": "<p>You cannot begin Snapshot without completing Step 3: Practice Snapshot. You will have one chance to record your video responses to the {numQuestions} interview questions. <strong>All responses will be submitted for review.</strong></p>",
    "step4Title": "Step 4: Start Snapshot Interview",
    "systemCheck": "System Check",
    "troubleshootingTips": "Troubleshooting Tips",
    "welcomeDescription": "Showcase your communication and interpersonal skills through this video response tool, and help programs get to know you better.",
    "welcomeSnapshot": "Welcome to Snapshot",
    "welcomeUser": "Welcome"
  },
  "fr": {
    "change": "Changer",
    "completeStep2First": "Vous devez d'abord compléter l'étape 2 : Vérification du système",
    "completeStep3First": "Vous devez d'abord compléter l'étape 3 : Entraînement Snapshot",
    "mandatoryCompletion": "La vérification du système est requise",
    "practiceSnapshot": "Entraînement Snapshot",
    "srChangeTest": "Changer l'examen sélectionné",
    "srOpenNewTab": "(ouvrir dans un nouvel onglet)",
    "startSnapshot": "Commencer l'entrevue Snapshot",
    "step1Description": "<p>L'objectif de Snapshot est de reproduire un environnement d'entrevue asynchrone en ligne, pour offrir aux programmes un aperçu de vos aptitudes en communication et de vos compétences interpersonnelles grâce à des réponses vidéos. C'est également une occasion pour vous de démontrer vos qualités personnelles et votre motivation aux programmes auxquels vous postulez.</p><p>Lisez la page d'information Snapshot destinée aux candidat(e)s sur <a href='https://votrealtus.com/'>VotreAltus.com</a> avant de commencer. Il est obligatoire d'effectuer l'étape 2 : Vérification du système, pour vous assurer que votre ordinateur est compatible pour compléter Snapshot. Étape 3 : Entraînement Snapshot est obligatoire afin de pouvoir procéder à l'étape 4 : Commencer l'entrevue Snapshot. L'entraînement vous aidera à vous mettre à l'aise avant de compléter l'étape 4, lors de laquelle vos vidéos seront envoyées à vos programmes.</p><p>Avant de débuter l'étape 4 : Commencer l'entrevue Snapshot, nous vous recommandons de vous assurer que vous avez accès à un lieu calme pour environ 10 minutes.</p>",
    "step1Title": "Étape 1 : En savoir plus sur Snapshot",
    "step2Description": "La vérification du système est requise {mandatoryCompletion}, afin de vous assurer que vous pourrez compléter Snapshot sans rencontrer de problèmes techniques. Lisez nos {troubleshootingLink} si vous rencontrez des problèmes techniques.",
    "step2Title": "Étape 2 : Vérification du système",
    "step3Description": "<p>Familiarisez-vous avec le format de Snapshot en répondant à deux questions d'entraînement. Vous pourrez réenregistrer et visionner vos vidéos autant de fois que vous le voulez.</p>",
    "step3Title": "Étape 3 : Entraînement Snapshot",
    "step4Description": "<p>Vous ne pouvez pas commencer Snapshot avant d'avoir complété l'étape 3 : Entraînement Snapshot. Vous pourrez enregistrer vos réponses vidéos aux {numQuestions} questions d'entrevue seulement une fois. <strong>Toutes les réponses seront envoyées à vos programmes pour évaluation.</strong></p>",
    "step4Title": "Étape 4 : Commencer l'entrevue Snapshot",
    "systemCheck": "Vérification du système",
    "troubleshootingTips": "Conseils de dépannage",
    "welcomeDescription": "Démontrez vos aptitudes en communication et vos compétences interpersonnelles grâce à cet outil de réponse vidéo automatisé, afin d'aider les programmes à mieux vous connaître.",
    "welcomeSnapshot": "Votre entrevue Snapshot",
    "welcomeUser": "Bienvenue"
  }
}
</i18n>
