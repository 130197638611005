<template>
  <div class="text-green-dark flex items-center my-3">
    <CheckmarkOutlined class="mr-2 w-6" aria-hidden="true" />
    <p>{{ t('completed') }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';
import CheckmarkOutlined from '@/components/common/CheckmarkOutlined.vue';

export default defineComponent({
  name: 'SuccessfullyCompletedNotice',
  components: {
    CheckmarkOutlined
  },
  setup() {
    return { t: useI18n().t };
  }
});
</script>

<i18n>
{
  "en": {
    "completed": "Successfully Completed"
  },
  "fr": {
    "completed": "Complété"
  }
}
</i18n>
