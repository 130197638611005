
import { computed, defineComponent, ref, toRef } from 'vue';
import { useI18n } from '@/services/i18n';
import { useStore } from '@/store/lib/store';
import { applicantStore } from '@/store/applicant';
import { authStore } from '@/store/auth';

import { getLangName } from '@/services/langNames';
import SuccessfullyCompletedNotice from '@/components/common/SuccessfullyCompletedNotice.vue';
import WelcomeCard from '@/components/common/WelcomeCard.vue';

export default defineComponent({
  name: 'ApplicantHome',
  components: { SuccessfullyCompletedNotice, WelcomeCard },
  setup() {
    const applicant = useStore(applicantStore);
    const auth = useStore(authStore);
    const i18n = useI18n();

    const userName = computed(() => auth.user?.profile?.givenname);

    const test = toRef(applicant, 'currentTest');

    const completedStep2 = computed(() => applicant.isSystemCheckDone);
    const completedStep3 = computed(() => applicant.isPracticeDone);
    const completedStep4 = ref(false);

    // TODO: This number should be coming from the api (maybe we could save it in the store?)
    const numQuestions = ref(3);

    return {
      userName,
      completedStep2,
      completedStep3,
      completedStep4,
      numQuestions,
      test,
      getLangName,
      t: i18n.t,
      i18n
    };
  }
});
