export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change"])},
        "completeStep2First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must first complete Step 2: System Requirements Check"])},
        "completeStep3First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You must first complete Step 3: Practice Snapshot"])},
        "mandatoryCompletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Requirements Check is required"])},
        "practiceSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice Snapshot"])},
        "srChangeTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change selected test"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(opens in a new tab)"])},
        "startSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Snapshot Interview"])},
        "step1Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>The aim of Snapshot is to mimic the setting of an asynchronous virtual interview to give programs a glimpse into your communication and interpersonal skills via video response. This is also an opportunity for you to demonstrate your personal attributes to the programs you are applying to.</p><p>Review the Altus Suite Snapshot Applicant Information page on <a href='https://www.takealtus.com'>TakeAltus.com</a> before you begin. It's obligatory that you complete Step 2: System Requirements Check to ensure your computer will be adequate come the day you take Snapshot. Step 3: Practice Snapshot, is a mandatory requirement before moving on to Step 4: Start Snapshot Interview. It will help you feel comfortable before completing Step 4, when your video responses will be submitted to your selected programs.</p><p>Before starting Step 4: Start Snapshot Interview, we recommend setting aside <strong>10 minutes in a quiet location</strong> to complete it.</p>"])},
        "step1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 1: Learn About Snapshot"])},
        "step2Description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Completing the ", _interpolate(_named("mandatoryCompletion")), " to ensure you have a smooth technical experience. Visit our ", _interpolate(_named("troubleshootingLink")), " if you encounter any technical issues."])},
        "step2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 2: System Requirements Check"])},
        "step3Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Familiarize yourself with the format of Snapshot by completing 2 practice questions. You will be able to re-record and re-watch your videos as much as you like.</p>"])},
        "step3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 3: Practice Snapshot"])},
        "step4Description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>You cannot begin Snapshot without completing Step 3: Practice Snapshot. You will have one chance to record your video responses to the ", _interpolate(_named("numQuestions")), " interview questions. <strong>All responses will be submitted for review.</strong></p>"])},
        "step4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Step 4: Start Snapshot Interview"])},
        "systemCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System Check"])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Troubleshooting Tips"])},
        "welcomeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showcase your communication and interpersonal skills through this video response tool, and help programs get to know you better."])},
        "welcomeSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Snapshot"])},
        "welcomeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])}
      },
      "fr": {
        "change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer"])},
        "completeStep2First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez d'abord compléter l'étape 2 : Vérification du système"])},
        "completeStep3First": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez d'abord compléter l'étape 3 : Entraînement Snapshot"])},
        "mandatoryCompletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La vérification du système est requise"])},
        "practiceSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entraînement Snapshot"])},
        "srChangeTest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer l'examen sélectionné"])},
        "srOpenNewTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(ouvrir dans un nouvel onglet)"])},
        "startSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l'entrevue Snapshot"])},
        "step1Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>L'objectif de Snapshot est de reproduire un environnement d'entrevue asynchrone en ligne, pour offrir aux programmes un aperçu de vos aptitudes en communication et de vos compétences interpersonnelles grâce à des réponses vidéos. C'est également une occasion pour vous de démontrer vos qualités personnelles et votre motivation aux programmes auxquels vous postulez.</p><p>Lisez la page d'information Snapshot destinée aux candidat(e)s sur <a href='https://votrealtus.com/'>VotreAltus.com</a> avant de commencer. Il est obligatoire d'effectuer l'étape 2 : Vérification du système, pour vous assurer que votre ordinateur est compatible pour compléter Snapshot. Étape 3 : Entraînement Snapshot est obligatoire afin de pouvoir procéder à l'étape 4 : Commencer l'entrevue Snapshot. L'entraînement vous aidera à vous mettre à l'aise avant de compléter l'étape 4, lors de laquelle vos vidéos seront envoyées à vos programmes.</p><p>Avant de débuter l'étape 4 : Commencer l'entrevue Snapshot, nous vous recommandons de vous assurer que vous avez accès à un lieu calme pour environ 10 minutes.</p>"])},
        "step1Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape 1 : En savoir plus sur Snapshot"])},
        "step2Description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["La vérification du système est requise ", _interpolate(_named("mandatoryCompletion")), ", afin de vous assurer que vous pourrez compléter Snapshot sans rencontrer de problèmes techniques. Lisez nos ", _interpolate(_named("troubleshootingLink")), " si vous rencontrez des problèmes techniques."])},
        "step2Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape 2 : Vérification du système"])},
        "step3Description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<p>Familiarisez-vous avec le format de Snapshot en répondant à deux questions d'entraînement. Vous pourrez réenregistrer et visionner vos vidéos autant de fois que vous le voulez.</p>"])},
        "step3Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape 3 : Entraînement Snapshot"])},
        "step4Description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<p>Vous ne pouvez pas commencer Snapshot avant d'avoir complété l'étape 3 : Entraînement Snapshot. Vous pourrez enregistrer vos réponses vidéos aux ", _interpolate(_named("numQuestions")), " questions d'entrevue seulement une fois. <strong>Toutes les réponses seront envoyées à vos programmes pour évaluation.</strong></p>"])},
        "step4Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étape 4 : Commencer l'entrevue Snapshot"])},
        "systemCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du système"])},
        "troubleshootingTips": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils de dépannage"])},
        "welcomeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démontrez vos aptitudes en communication et vos compétences interpersonnelles grâce à cet outil de réponse vidéo automatisé, afin d'aider les programmes à mieux vous connaître."])},
        "welcomeSnapshot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre entrevue Snapshot"])},
        "welcomeUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])}
      }
    }
  })
}
