
import { defineComponent } from 'vue';
import { useI18n } from '@/services/i18n';
import CheckmarkOutlined from '@/components/common/CheckmarkOutlined.vue';

export default defineComponent({
  name: 'SuccessfullyCompletedNotice',
  components: {
    CheckmarkOutlined
  },
  setup() {
    return { t: useI18n().t };
  }
});
