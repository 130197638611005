<template>
  <div class="card-container flex items-center">
    <div class="pr-10 pl-7 flex-none">
      <img src="@/assets/images/snapshot-logo-circle.svg" alt="" class="w-28" />
    </div>

    <div class="flex-grow">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'WelcomeCard'
});
</script>

<style lang="postcss" scoped>
.card-container {
  @apply px-6 py-9 border-2 border-gray-lighter rounded-lg shadow-lg text-gray-darkest bg-gray-lightest;
}
</style>
